import { Bars3Icon } from "@heroicons/react/24/outline";
import { useState } from "react";
import SidebarMobile from "./SidebarMobile";
import logo from "../assets/infosan-logo.png";
import { useLocation, useNavigate } from "react-router-dom";
import PhoneButton from "../assets/PhoneButton.png";
import { Switch } from "@headlessui/react";
import enflag from "../assets/enflag.png";
import roflag from "../assets/roflag.png";
import moment from "moment-timezone";
function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}
const checkTimeInRomania = () => {
  // Setează zona orară pentru România
  const now = moment.tz("Europe/Bucharest");

  // Definește intervalul orar
  const startHour = moment
    .tz("Europe/Bucharest")
    .startOf("day")
    .set({ hour: 8, minute: 0, second: 0 });
  const endHour = moment
    .tz("Europe/Bucharest")
    .startOf("day")
    .set({ hour: 18, minute: 30, second: 0 });

  // Verifică dacă ora curentă este între 8:00 și 18:30 și dacă ziua este între Luni și Vineri
  const isWeekday = now.isoWeekday() >= 1 && now.isoWeekday() <= 5; // 1 = Luni, 5 = Vineri
  const isInTimeInterval = now.isBetween(startHour, endHour);

  return isWeekday && isInTimeInterval;
};

const Header = ({ lang, sidebar = true }) => {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [enabled, setEnabled] = useState(lang !== "ro");
  const location = useLocation();
  const navigate = useNavigate();
  const isWithinTimeRange = checkTimeInRomania();

  return (
    <header className="bg-[#001529] h-16 z-50 fixed top-0 w-full px-2 lg:px-10">
      <div className="flex items-center px-4 h-full lg:justify-between">
        <div className="flex items-center justify-between lg:justify-start">
          <button
            type="button"
            className={
              (sidebar === false ? "invisible" : "") +
              " -m-2.5 p-2.5 text-white lg:hidden"
            }
            onClick={() => setSidebarOpen(true)}
          >
            <span className="sr-only">Open sidebar</span>
            <Bars3Icon className="h-6 w-6" aria-hidden="true" />
          </button>
          <img src={logo} alt="logo" className="h-12 ml-2 lg:ml-0" />
          <span className="text-blue-500 ml-10 lg:ml-4">
            <img src={roflag} alt="ro" className="h-4" />
          </span>
          <div className="mx-2">
            <Switch
              checked={enabled}
              onChange={(e) => {
                setEnabled(e);
                if (e) {
                  navigate("/en" + location.pathname);
                } else {
                  navigate(location.pathname.replace("/en", ""));
                }
              }}
              className={classNames(
                enabled ? "bg-[#67CAD2]" : "bg-gray-200",
                "border border-[#67CAD2] items-center relative inline-flex h-4 w-9 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out"
              )}
            >
              <span
                aria-hidden="true"
                className={classNames(
                  enabled ? "translate-x-5" : "translate-x-0",
                  "pointer-events-none inline-block h-3 w-3 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
                )}
              />
            </Switch>
          </div>
          <span className="text-blue-500">
            <img src={enflag} alt="en" className="h-4" />
          </span>
        </div>
        <div className="ml-4 hidden lg:block ">
          {isWithinTimeRange === true ? (
            <a
              href="tel:0212114067"
              className="flex gap-4 text-white px-4 py-1.5 bg-[#C60000] hover:bg-red-600 rounded-3xl items-center"
            >
              <img src={PhoneButton} alt="phone" className="h-7" />
              <span className="font-bold text-xl">021.211.4067</span>
            </a>
          ) : null}
        </div>

        <SidebarMobile
          isOpen={sidebarOpen}
          toggle={setSidebarOpen}
          lang={lang}
        />
      </div>
    </header>
  );
};

export default Header;

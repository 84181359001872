const REGIONS = [
  { name: "Sector 1", id: 1 },
  { name: "Sector 2", id: 2 },
  { name: "Sector 3", id: 3 },
  { name: "Sector 4", id: 4 },
  { name: "Sector 5", id: 5 },
  { name: "Sector 6", id: 6 },
  { name: "Alba", id: 7 },
  { name: "Arad", id: 8 },
  { name: "Arges", id: 9 },
  { name: "Bacau", id: 10 },
  { name: "Bihor", id: 11 },
  { name: "Bistrita-Nasaud", id: 12 },
  { name: "Botosani", id: 13 },
  { name: "Brasov", id: 14 },
  { name: "Braila", id: 15 },
  { name: "Bucuresti", id: 16 },
  { name: "Buzau", id: 17 },
  { name: "Caras-Severin", id: 18 },
  { name: "Calarasi", id: 19 },
  { name: "Cluj", id: 20 },
  { name: "Constanta", id: 21 },
  { name: "Covasna", id: 22 },
  { name: "Dambovita", id: 23 },
  { name: "Dolj", id: 24 },
  { name: "Galati", id: 25 },
  { name: "Giurgiu", id: 26 },
  { name: "Gorj", id: 27 },
  { name: "Harghita", id: 28 },
  { name: "Hunedoara", id: 29 },
  { name: "Ialomita", id: 30 },
  { name: "Iasi", id: 31 },
  { name: "Ilfov", id: 32 },
  { name: "Maramures", id: 33 },
  { name: "Mehedinti", id: 34 },
  { name: "Mures", id: 35 },
  { name: "Neamt", id: 36 },
  { name: "Olt", id: 37 },
  { name: "Prahova", id: 38 },
  { name: "Salaj", id: 39 },
  { name: "Satu Mare", id: 40 },
  { name: "Sibiu", id: 41 },
  { name: "Suceava", id: 42 },
  { name: "Teleorman", id: 43 },
  { name: "Timis", id: 44 },
  { name: "Tulcea", id: 45 },
  { name: "Valcea", id: 46 },
  { name: "Vaslui", id: 47 },
  { name: "Vrancea", id: 48 },
  { name: "Strainatate", id: 49 },
];

export { REGIONS };

import Header from "../components/Header";
import { useEffect } from "react";

import { useParams } from "react-router-dom";
import { getCheckInSummary, patientCheckIn } from "../api/General";
import CancelModal from "../components/CancelModal";
import { useState } from "react";
import Loading from "../components/Loading";
import moment from "moment-timezone";
import PhoneInput from "react-phone-input-2";
import { REGIONS } from "../utils/Constants";
import { toast } from "react-toastify";

const Confirm = ({ lang }) => {
  const { id } = useParams();
  const [open, setOpen] = useState(false);
  const [booking, setBooking] = useState({});
  const [cancelled, setCancelled] = useState(false);
  const [notFound, setNotFound] = useState(false);
  const [loading, setLoading] = useState(true);
  const [confirmationValid, setConfirmationValid] = useState(false);
  const [patientData, setPatientData] = useState({
    code: "",
    email: "",
    phone: "",
    area: "",
    mediul: "",
    area_id: "",
  });

  useEffect(() => {
    getCheckInSummary(id).then((res) => {
      if (res.status === 404) {
        setNotFound(true);
        setLoading(false);
        return;
      }
      setBooking(res.data);
      setLoading(false);
    });
  }, []);

  const onConfirm = () => {
    var contacts_data = [];
    if (
      booking?.missing_information?.email === false &&
      patientData.email !== ""
    ) {
      contacts_data.push({
        pacient_id: booking?.booking_data?.pacient_id,
        data: patientData.email,
        data_type: "email",
        subscribe: 1,
        obs: "",
      });
    }
    if (
      booking?.missing_information?.phone === false &&
      patientData.phone !== ""
    ) {
      contacts_data.push({
        pacient_id: booking?.booking_data?.pacient_id,
        data: patientData.phone,
        data_type: "phone",
        subscribe: 1,
        obs: "",
      });
    }

    const savedDate = {
      uuid: id,
      pacient_id: booking?.booking_data?.pacient_id,
      pacient_data: {
        code:
          booking?.missing_information?.code === false
            ? patientData.code
            : undefined,
        mediu:
          booking?.missing_information?.mediu === false
            ? parseInt(patientData.mediul)
            : undefined,
        area:
          booking?.missing_information?.area === false ||
          booking?.missing_information?.area_id === false
            ? patientData.area
            : undefined,
        area_id:
          booking?.missing_information?.area_id === false ||
          booking?.missing_information?.area === false
            ? patientData.area_id
            : undefined,
      },
      contacts_data: contacts_data,
    };

    const res = patientCheckIn(savedDate).then((res) => {
      if (res.status === 200) {
        toast.success(
          lang === "ro"
            ? "Programarea a fost confirmată cu succes"
            : "Appointment confirmed successfully",
          {
            style: {
              width: "300px",
            },
          }
        );
        setConfirmationValid(true);
      } else {
        toast.error(
          lang === "ro"
            ? "A apărut o eroare la confirmarea programării"
            : "An error occurred while confirming the appointment",
          {
            style: {
              width: "300px",
            },
          }
        );
      }
    });
    toast.promise(
      res,
      {
        pending:
          lang === "ro"
            ? "Se confirma programarea..."
            : "Confirming appointment...",
      },
      { style: { width: "300px" } }
    );
  };
  return (
    <>
      <Header lang={lang} sidebar={false} />
      <CancelModal
        lang={lang}
        open={open}
        setOpen={setOpen}
        booking={booking}
      />
      <div>
        <main className=" mt-16">
          <div className="px-4 sm:px-6 lg:px-8 py-20">
            <section className="m-7 p-7 bg-white max-w-6xl mx-auto">
              <div className="text-sm align-center text-center">
                {loading === true ? (
                  <div className="text-center mx-auto flex justify-center">
                    <Loading />
                  </div>
                ) : (
                  <>
                    {notFound === true ? (
                      <h3 className="my-2.5 leading-7 text-2xl font-semibold text-center">
                        {lang === "ro"
                          ? "Programarea nu a fost găsită"
                          : "Appointment not found"}
                      </h3>
                    ) : confirmationValid === true ? (
                      <>
                        <h3 className="my-2.5 leading-7 text-2xl font-semibold text-center">
                          {lang === "ro"
                            ? "Programarea a fost confirmată cu succes"
                            : "Appointment confirmed successfully"}
                        </h3>
                        <h3 className="my-2.5 leading-7 text-2xl font-semibold text-center">
                          {lang === "ro" ? (
                            <span>
                              Vă așteptăm în data de{" "}
                              {moment
                                .utc(booking?.booking_data?.programat)
                                .tz("Europe/Bucharest")
                                .locale("ro")
                                .format("dddd, DD MMMM YYYY, [ora] HH:mm")}
                            </span>
                          ) : (
                            <span>
                              We are waiting for you on{" "}
                              {moment
                                .utc(booking?.booking_data?.programat)
                                .tz("Europe/Bucharest")
                                .locale("en")
                                .format("dddd, DD MMMM YYYY, [at] HH:mm")}
                            </span>
                          )}
                        </h3>
                      </>
                    ) : (
                      <>
                        {booking?.booking_data.confirmed === 1 ? (
                          <h3 className="my-2.5 leading-7 text-2xl font-semibold text-center">
                            {lang === "ro"
                              ? "Programarea a fost deja confirmată"
                              : "Appointment already confirmed"}
                          </h3>
                        ) : (
                          <>
                            <h3 className="my-2.5 leading-7 text-2xl font-semibold text-center">
                              {lang === "ro"
                                ? "Confirmare programare"
                                : "Appointment confirmation"}
                            </h3>
                            <p className="text-xl text-center my-10">
                              {lang === "ro" ? (
                                <>
                                  {Object.keys(booking?.missing_information)
                                    .length === 0
                                    ? "Vă rugăm să confirmați programarea din data de "
                                    : "Vă rugăm să completați datele de mai jos pentru a confirma programarea din data de "}
                                  <strong>
                                    {moment
                                      .utc(booking?.booking_data?.programat)
                                      .tz("Europe/Bucharest")
                                      .locale("ro")
                                      .format(
                                        "dddd, DD MMMM YYYY, [ora] HH:mm"
                                      )}
                                  </strong>
                                  , pe numele{" "}
                                  <strong>
                                    {booking?.booking_data?.pacient_first_name}{" "}
                                    {booking?.booking_data?.pacient_last_name}
                                  </strong>
                                </>
                              ) : (
                                <>
                                  {Object.keys(booking?.missing_information)
                                    .length === 0
                                    ? "Please confirm the appointment from "
                                    : "Please fill in the details below to confirm the appointment from "}
                                  <strong>
                                    {moment
                                      .utc(booking?.booking_data?.programat)
                                      .tz("Europe/Bucharest")
                                      .locale("en")
                                      .format("dddd, DD MMMM YYYY, [at] HH:mm")}
                                  </strong>
                                  , on behalf of{" "}
                                  <strong>
                                    {booking?.booking_data?.pacient_first_name}{" "}
                                    {booking?.booking_data?.pacient_last_name}
                                  </strong>
                                </>
                              )}
                            </p>
                            <div className="w-full ml-0 mb-10">
                              <div className="grid grid-cols-9 gap-2 lg:gap-4 mb-10">
                                {booking?.missing_information?.code ===
                                false ? (
                                  <>
                                    <div className="col-span-9 flex justify-start lg:justify-end items-center lg:col-span-2 ">
                                      <label
                                        htmlFor="cnp"
                                        className="block text-sm text-right justify-center align-center"
                                      >
                                        <span className="text-red-500 text-lg">
                                          *
                                        </span>
                                        CNP:
                                      </label>
                                    </div>
                                    <div className="col-span-9 lg:col-span-7 flex justify-start">
                                      <input
                                        type="text"
                                        id="cnp"
                                        name="cnp"
                                        value={patientData.code}
                                        onChange={(e) =>
                                          setPatientData({
                                            ...patientData,
                                            code: e.target.value,
                                          })
                                        }
                                        className="w-full lg:w-4/5 border border-gray-300 rounded-md px-2 py-1.5 focus:shadow-blue-400 focus:ring-1 focus:ring-blue-500 focus:border-blue-500 focus:outline-none focus:shadow"
                                      />
                                    </div>
                                  </>
                                ) : null}
                                {booking?.missing_information?.email ===
                                false ? (
                                  <>
                                    <div className="col-span-9 flex justify-start lg:justify-end items-center lg:col-span-2 ">
                                      <label
                                        htmlFor="email"
                                        className="block text-sm text-right justify-center align-center"
                                      >
                                        <span className="text-red-500 text-lg">
                                          *
                                        </span>
                                        E-mail:
                                      </label>
                                    </div>
                                    <div className="col-span-9 lg:col-span-7 flex justify-start">
                                      <input
                                        type="email"
                                        id="email"
                                        name="email"
                                        required
                                        value={patientData.email}
                                        onChange={(e) =>
                                          setPatientData({
                                            ...patientData,
                                            email: e.target.value,
                                          })
                                        }
                                        className="w-full lg:w-4/5 border border-gray-300 rounded-md px-2 py-1.5 focus:shadow-blue-400 focus:ring-1 focus:ring-blue-500 focus:border-blue-500 focus:outline-none focus:shadow"
                                      />
                                    </div>
                                  </>
                                ) : null}
                                {booking?.missing_information?.phone ===
                                false ? (
                                  <>
                                    <div className="col-span-9 flex justify-start lg:justify-end items-center lg:col-span-2 ">
                                      <label
                                        htmlFor="phone"
                                        className="block text-sm text-right justify-center align-center"
                                      >
                                        <span className="text-red-500 text-lg">
                                          *
                                        </span>{" "}
                                        {lang === "ro"
                                          ? "Telefon:"
                                          : "Mobile Phone:"}
                                      </label>
                                    </div>
                                    <div className="col-span-9 lg:col-span-7 w-full lg:w-4/5">
                                      <PhoneInput
                                        country={"ro"}
                                        inputStyle={{
                                          width: "100%",
                                          height: "34px",
                                          border: "1px solid #ccc",
                                          borderRadius: "4px",
                                        }}
                                        preferredCountries={["ro"]}
                                        countryCodeEditable={false}
                                        inputProps={{
                                          name: "phone",
                                          required: true,
                                        }}
                                        value={patientData.phone}
                                        onChange={(phone) =>
                                          setPatientData({
                                            ...patientData,
                                            phone: phone,
                                          })
                                        }
                                      />
                                    </div>
                                  </>
                                ) : null}
                                {booking?.missing_information?.area === false ||
                                booking?.missing_information?.area_id ===
                                  false ? (
                                  <>
                                    <div className="col-span-9 flex justify-start lg:justify-end items-center lg:col-span-2 ">
                                      <label
                                        htmlFor="area_id"
                                        className="block text-sm text-right justify-center align-center"
                                      >
                                        <span className="text-red-500 text-lg">
                                          *
                                        </span>{" "}
                                        {lang === "ro"
                                          ? "Județ / Sector:"
                                          : "County / Sector:"}
                                      </label>
                                    </div>
                                    <div className="col-span-9 lg:col-span-7 flex justify-start">
                                      <select
                                        id="area_id"
                                        name="area_id"
                                        value={patientData.area_id}
                                        onChange={(e) =>
                                          setPatientData({
                                            ...patientData,
                                            area_id: parseInt(e.target.value),
                                            area: e.target.options[
                                              e.target.selectedIndex
                                            ].text,
                                          })
                                        }
                                        className="w-full lg:w-4/5 border border-gray-300 rounded-md px-2 py-1.5 focus:shadow-blue-400 focus:ring-1 focus:ring-blue-500 focus:border-blue-500 focus:outline-none focus:shadow"
                                      >
                                        <option value="" hidden>
                                          Selectează
                                        </option>
                                        {REGIONS.map((region) => {
                                          return (
                                            <option
                                              value={region.id}
                                              key={region.id}
                                            >
                                              {region.name}
                                            </option>
                                          );
                                        })}
                                      </select>
                                    </div>
                                  </>
                                ) : null}
                                {booking?.missing_information?.mediu ===
                                false ? (
                                  <>
                                    <div className="col-span-9 flex justify-start lg:justify-end items-center lg:col-span-2 ">
                                      <label
                                        htmlFor="mediu"
                                        className="block text-sm text-right justify-center align-center"
                                      >
                                        <span className="text-red-500 text-lg">
                                          *
                                        </span>
                                        {lang === "ro" ? "Mediu:" : "City:"}
                                      </label>
                                    </div>
                                    <div className="col-span-9 lg:col-span-7 flex justify-start">
                                      <select
                                        id="mediu"
                                        name="mediu"
                                        value={patientData.mediul}
                                        onChange={(e) =>
                                          setPatientData({
                                            ...patientData,
                                            mediul: e.target.value,
                                          })
                                        }
                                        className="w-full lg:w-4/5 border border-gray-300 rounded-md px-2 py-1.5 focus:shadow-blue-400 focus:ring-1 focus:ring-blue-500 focus:border-blue-500 focus:outline-none focus:shadow"
                                      >
                                        <option value="" hidden>
                                          Selectează
                                        </option>
                                        <option value="1">Urban</option>
                                        <option value="2">Rural</option>
                                      </select>
                                    </div>
                                  </>
                                ) : null}
                              </div>
                              <table className="w-full">
                                <thead className="bg-[#f7f4f4]">
                                  <tr>
                                    <th
                                      colSpan={3}
                                      className="text-left p-1.5 border border-[#ccc] text-sm font-semibold"
                                    >
                                      {lang === "ro"
                                        ? "DESCRIERE"
                                        : "DESCRIPTION"}
                                    </th>
                                    <th className="text-left p-1.5 border border-[#ccc] text-sm font-semibold">
                                      {lang === "ro" ? "PRET" : "PRICE"}
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr>
                                    <td
                                      colSpan={3}
                                      className="text-left p-2  border border-[#ccc]"
                                    >
                                      <span className="font-semibold">
                                        {
                                          booking?.booking_data?.prices?.package
                                            ?.name
                                        }
                                      </span>
                                      <br></br>
                                      <span>
                                        Doctor:{" "}
                                        {booking?.booking_data?.user_first_name}{" "}
                                        {booking?.booking_data?.user_last_name}
                                      </span>
                                    </td>
                                    <td className="p-2 border border-[#ccc] text-left ">
                                      {parseInt(
                                        booking?.booking_data?.prices?.package
                                          ?.price
                                      )}{" "}
                                      lei
                                    </td>
                                  </tr>
                                  {booking?.booking_data?.prices?.manevre
                                    ?.length > 0 ? (
                                    <>
                                      <tr>
                                        <td
                                          colSpan={4}
                                          className="text-left p-1.5 border border-[#ccc] text-sm font-semibold bg-[#f7f4f4]"
                                        >
                                          {lang === "ro"
                                            ? "INVESTIGATII"
                                            : "INVESTIGATIONS"}
                                        </td>
                                      </tr>
                                      {booking?.booking_data?.prices?.manevre.map(
                                        (man) => {
                                          return (
                                            <tr>
                                              <td
                                                colSpan={3}
                                                className="text-left p-2  border border-[#ccc]"
                                              >
                                                <span className="font-semibold">
                                                  {man.name}
                                                </span>
                                              </td>
                                              <td className="p-2 border border-[#ccc] text-left ">
                                                {parseInt(man.price)} lei
                                              </td>
                                            </tr>
                                          );
                                        }
                                      )}
                                    </>
                                  ) : null}
                                  <tr className="bg-[#fffcce]">
                                    <td
                                      className="text-right font-semibold p-2 border border-[#ccc]"
                                      colSpan={3}
                                    >
                                      TOTAL
                                    </td>
                                    <td className="font-semibold p-2 border border-[#ccc] text-left">
                                      {parseInt(
                                        booking?.booking_data?.prices?.total
                                      )}{" "}
                                      lei
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                            <button
                              type="button"
                              onClick={onConfirm}
                              className="align-center mx-auto bg-secondary hover:bg-primary text-white px-4 py-1.5 rounded text-2xl "
                            >
                              {lang === "ro"
                                ? "Confirmă programarea"
                                : "Confirm appointment"}
                            </button>
                          </>
                        )}
                      </>
                    )}
                  </>
                )}
              </div>
            </section>
          </div>
        </main>
      </div>
    </>
  );
};

export default Confirm;
